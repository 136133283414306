import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnInit,
	computed,
	inject,
	signal
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AuthFacade } from '@yuno/angular-auth';
import {
	YunoListItemComponent,
	YunoListItemLoaderComponent,
	YunoUiHeaderComponent
} from '@yuno/yuno/features/ui';

import { AppListStore } from '../../data-access';

@Component({
	selector: 'yuno-feat-app-list',
	standalone: true,
	imports: [
		AsyncPipe,
		RouterLink,
		FormsModule,
		YunoListItemComponent,
		YunoListItemLoaderComponent,
		InfiniteScrollModule,
		YunoUiHeaderComponent
	],
	template: `
		<!-- The scroll detection needs this Absolute positioned div -->
		<div
			class="px-safe-containers absolute inset-0 flex flex-col gap-4 overflow-y-auto py-4 md:py-8"
			infinite-scroll
			[infiniteScrollDistance]="scrollDistance"
			[infiniteScrollUpDistance]="scrollUpDistance"
			[infiniteScrollThrottle]="throttle"
			[scrollWindow]="false"
			(scrolled)="onScrollDown()">
			@if (user$ | async; as user) {
				<yuno-ui-header [title]="'Welcome ' + user.displayName" />
			}
			<span>
				In this mobile Portal you can view your Project Atlasses, upload photos to a map and
				manage incoming participation messages.
			</span>

			<div>
				<h2>Project Atlasses</h2>

				<!-- Only shows the Searchbar when more than 5 apps are available to the user -->
				@if ($appList().length > 5) {
					<div class="mb-4">
						<label
							for="default-search"
							class="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white">
							Search
						</label>
						<div class="relative">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="absolute m-2.5 h-5 w-5">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
							</svg>

							<input
								type="search"
								id="default-search"
								class="block w-full rounded-md border border-yuno-blue-gray-300 bg-yuno-blue-gray-100 px-4 pe-10 ps-10 focus:border-yuno-blue-gray-500 focus:ring-yuno-blue-gray-500"
								placeholder="Search..."
								[(ngModel)]="searchedValue"
								(ngModelChange)="onKeyUp($event)" />
							<!-- When a search value is entered we show the X button to clear the search -->
							@if (searchedValue.length) {
								<button
									class="absolute bottom-2.5 end-0 px-4 hover:opacity-65"
									(click)="clearFilter()">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="h-6 w-6">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M6 18 18 6M6 6l12 12" />
									</svg>
								</button>
							}
						</div>
					</div>
				}

				<!-- App List filtered by Scrolling -->
				<ul class="flex flex-col gap-1">
					@for (app of dataSourceSignal(); track app._id) {
						<yuno-ui-list-item>
							<a
								[routerLink]="['../', app._id]"
								class="flex h-full flex-1 flex-col items-start overflow-hidden p-4 pr-0">
								<span class="max-w-full truncate">
									{{ app.id }}
								</span>
								<span class="max-w-full truncate text-sm">
									{{ app.client.id }}
								</span>
							</a>

							<ng-container actions>
								<a
									[routerLink]="['../', app._id]"
									class="py-4 text-sm hover:opacity-65">
									Edit
								</a>
								<a
									[routerLink]="['../', app._id, 'view']"
									class="inline-block py-4 pr-4 text-sm text-yuno-portal-blue hover:opacity-65">
									View
								</a>
							</ng-container>
						</yuno-ui-list-item>
					} @empty {
						<yuno-ui-list-item-loader [isLoading]="$isLoading()" />
					}
				</ul>
			</div>
		</div>
	`,
	styles: `
		:host {
			display: block;
			inset: 0;
			position: absolute;
			overflow: hidden;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoMobileAppListComponent implements OnInit {
	private readonly el = inject(ElementRef);
	private readonly authFacade = inject(AuthFacade);
	readonly store = inject(AppListStore);

	initialValue = 15;
	searchedValue = '';

	throttle = 300;
	scrollDistance = 1;
	scrollUpDistance = 2;

	$apps = this.store.filtered;
	$appList = this.store.data;
	$isLoading = this.store.isLoading;

	user$ = this.authFacade.user$;

	private limitSignal = signal<number>(this.initialValue);

	dataSourceSignal = computed(() => {
		return this.$apps().slice(0, this.limitSignal());
	});

	ngOnInit() {
		this.setInitialValue();
		this.store.getAppList();
	}

	setInitialValue(): void {
		const offsetHeight = this.el.nativeElement.offsetHeight;
		const itemHeight = 76;

		// Return 15 items if the height of the container is less than 15 items
		this.initialValue = Math.max(Math.ceil(offsetHeight / itemHeight) + 2, 15);
		this.limitSignal.set(this.initialValue);
	}

	onScrollDown(): void {
		this.limitSignal.update(val => val + this.initialValue);
	}

	onKeyUp(event: string): void {
		this.store.setQuery(event);
	}

	clearFilter(): void {
		this.searchedValue = '';
		this.store.setQuery('');
	}
}
