import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';

import { ApiObservableService } from '@yuno/angular/api';
import { App, AppListData } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class AppsService {
	private readonly api = inject(ApiObservableService);

	getAppList(): Observable<AppListData[]> {
		return this.api.get<App[]>(`app/portal`).pipe(
			map(data => {
				return data.map(app => {
					return {
						_id: app._id,
						id: app.id,
						public: app.public,
						client: app.client,
						config: app.config
					};
				}) as AppListData[];
			}),
			take(1)
			// catchError(error => []);
		);
	}
}
